import React from 'react';

export default class DocumentFooter extends React.Component {
    render() {
        return (
            <div className="d-flex justify-content-around document-footer">
                <div className="document-footer-box d-flex justify-content-center align-items-end">
                    <p className="small-fontSize">
                        imię,nazwisko i podpis osoby upowaznionej do odebrania dokumentu
                    </p>
                </div>
                <div className="document-footer-box d-flex justify-content-center align-items-end">
                    <p className="small-fontSize">
                        Wystawił(a):
                    </p>
                </div>
            </div>
        )
    }
} 