import _ from 'lodash';

class Acl {

    setPermissions(permissions) {
        this.permissions = permissions;
    }

    setIsAllowedState(state, isAdmin) {
        let isAllowed = false;
        if (isAdmin) return isAllowed = true;

        const currentModuleIndex = _.findIndex(this.permissions, { 'module': state });

        if (currentModuleIndex >= 0) {
            // const permission = this.permissions[currentModuleIndex];
            isAllowed = true;
        }

        return isAllowed;
    }

    getPermission(moduleName, controllerName) {
        const permissions = this.getPermissions();
        let permission = _.find(permissions, (pm) => {
            if (pm.module === moduleName && pm.controller === controllerName) {
                return true;
            }
            return false;
        });
        return permission;
    }

    getPermissions() {
        return this.permissions;
    }

    isAllowedAction = (moduleName, controllerName, action, isAdmin) => {
        if (isAdmin) return true;
        const permissions = this.getPermissions();
        let permission = _.find(permissions, (pm) => {
            if (pm.module === moduleName && pm.controller === controllerName) {
                return true;
            }
            return false;
        });
        if (permission) {
            return _.includes(permission.actions, action);
        } else {
            return false;
        }
    }

}


export default new Acl();