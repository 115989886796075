import React from 'react';

export default class DocumentAddressBox extends React.Component {
    render() {
        const { postCode, street, houseNumber, city, nip, phone, phone2, mail, name, isCustomer } = this.props;
        return (
            <div className="document-address-box" >

                {(name) ? <div> {(isCustomer) ? 'Odbiorca:' : 'Dostawca:'} <strong>{name}</strong></div> : null}

                {(city) ?
                    <div className="d-flex flex-row" style={{ margin: 0 }}>
                        <p> Adres: </p>
                        <div className="d-flex flex-row document-address-row">
                            <strong><p>{(postCode) ? postCode : null}</p></strong>
                            <strong><p>{(city) ? `${city}, ` : null}</p></strong>
                            <strong> <p>{(street) ? street : null}</p></strong>
                            <strong><p>{(houseNumber) ? houseNumber : null}</p></strong>
                        </div>
                    </div> : null}

                {(nip) ? <div>NIP: <strong>{nip}</strong></div> : null}
                <div className="d-flex flex-row">
                    {(phone) ? <div>Telefon: <strong>{phone}</strong> </div> : null}
                    
                    {(phone2) ? <strong>{`, ${phone}`}</strong> : null}
                </div>
                {(mail) ? <div>E-mail: <strong>{mail}</strong> </div> : null}

            </div>
        )
    }
}