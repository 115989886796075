import React from 'react';

export default class Pagination extends React.Component {

    state = {
        page: 1
    }

    changePage = (newPage) => {
        const { changeOffset, perPage, getDocuments, total, page, setPage } = this.props;
        let nr_of_pages = Math.ceil(total / perPage);
        if (nr_of_pages === 0) {
            nr_of_pages = 1;
        }

        if (newPage >= 1 && newPage !== page && newPage && newPage <= nr_of_pages) {
            changeOffset((newPage - 1) * perPage);
            getDocuments();
            setPage(newPage);
        }
    }

    render() {
        const { total, perPage, page } = this.props;

        let nr_of_pages = Math.ceil(total / perPage);
        if (nr_of_pages === 0) {
            nr_of_pages = 1;
        }

        return (<div className="row mt-4 align-items-center user-select-none">
            <div className="col-6">
                Znaleziono {total} wyników
            </div>
            <div className="col-6">
                <ul className="pagination pagination-sm justify-content-end mb-2">
                    <li className="page-item" onClick={() => this.changePage(1)}><button className="page-link text-dark">&#10094;&#10094;</button></li>
                    <li className="page-item" onClick={() => this.changePage(page - 1)}>
                        <button className="page-link text-dark">&#10094;</button>
                    </li>
                    <li className="page-item"><button className="page-link text-dark">{page} z {nr_of_pages}</button></li>
                    <li className="page-item" onClick={() => this.changePage(page + 1)}>
                        <button className="page-link text-dark">&#10095;</button>
                    </li>
                    <li className="page-item"><button className="page-link text-dark" onClick={() => this.changePage(nr_of_pages)}>&#10095;&#10095;</button></li>
                </ul>
            </div>
        </div>);
    }

}