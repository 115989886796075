import React from 'react';

export default class DocumentHeader extends React.Component {
    stamp = () => {
        const { forDocument } = this.props;
        if (forDocument) {
            return (
                <div className="d-flex col-4 flex-column">
                    <div className="document-border d-flex justify-content-center align-items-end" style={{ height: "70%" }}>
                        <p className="small-fontSize">pieczęć firmy</p>
                    </div>
                    <div className="d-flex document-border align-content-center flex-column align-items-center" style={{ height: "30%" }}>
                        <p style={{ margin: 0 }}>{`${forDocument.documentNumber} ${forDocument.salesDate}`}</p>
                        <p className="small-fontSize">numer, data dokumentu korygowanego</p>
                    </div>
                </div>

            )
        } else {
            return (
                <div className="document-border d-flex col-4 justify-content-center align-items-end">
                    <p className="small-fontSize">pieczęć firmy</p>
                </div>
            )
        }
    }

    render() {
        const { name, salesDate } = this.props;
        return (
            <div className="d-flex flex-row document-header">

                {this.stamp()}

                <div className="d-flex col-4">
                    <div className="document-header-name big-fontSize d-flex justify-content-center align-items-center text-center">
                        {name}
                    </div>
                </div>

                <div className="d-flex col-4 flex-column original-container">
                    <div className="d-flex col-4 document-border  justify-content-center align-items-center">
                        Nr {this.props.documentNumber}
                    </div>
                    <div className="d-flex col-4   justify-content-center align-items-center">
                        <p className="big-fontSize">ORYGINAŁ</p>
                    </div >
                    <div className="d-flex col-4  document-border align-content-center flex-column align-items-center">
                        <p style={{ margin: 0 }}>{salesDate}</p>
                        <p className="small-fontSize">data wystawienia dokumentu</p>
                    </div>
                </div>



            </div>
        )
    }
}