import React from 'react';

export default class InputSelect extends React.Component {

    options = () => {
        const { options } = this.props;
        if (options) {
            return options.map(option => {
                return (
                    <option key={option} value={option}>
                        {option}
                    </option>
                )
            })
        }
    }
    render() {
        return (
            <div className={this.props.className}>
                <label
                // className="cms-item__label"
                >
                    {this.props.label}
                </label>
                <select className="custom-select"
                    onChange={this.props.onChange}
                    id={this.props.id}
                    value={this.props.value}
                >
                    {this.options()}
                </select>
            </div>
        )
    }
}