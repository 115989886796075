import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route } from "react-router-dom";
import DashboardContainer from "../../dashboard/containers/dashboard.container";
import DocumentDetailsContainer from '../../documents/containers/documentDetails.container';
import DocumentEditContainer from '../../documents/containers/documentEdit.container';
import DocumentCompletionContainer from '../../documents/containers/documentCompletion.container';
import DocumentsContainer from '../../documents/containers/documents.container';
import EnovaImportContainer from "../../documents/containers/enovaImport.container";
// import SymfoniaImportContainer from '../../documents/containers/symfoniaImport.container';
import GeneratorCodeContainer from '../../generator/containers/generator.code.container';
import GeneratorlistContainer from '../../generator/containers/generator.list.container';
import InventoriesContainer from "../../inventory/containers/inventories.container";
import InventoryContainer from "../../inventory/containers/inventory.container";
import InventoryDetailsContainer from "../../inventory/containers/inventoryDetails.container";
import ProductContainer from '../../products/containers/product.container';
import ProductDetailsContainer from '../../products/containers/productDetails.container';
import ProductsContainer from '../../products/containers/products.container';
import ProfileContainer from "../../profile/containers/profile.container";
import ReportContainer from "../../report/containers/report.container";
import RoleContainer from "../../roles/containers/role.container";
import RoleDetailsContainer from "../../roles/containers/roleDetails.container";
import RolesContainer from "../../roles/containers/roles.container";
import AdministrationContainer from "../../administration/containers/administration.container";
import AclService from '../../services/acl.service';
import SettingsContainer from "../../settings/containers/settings.container";
import UserContainer from "../../users/containers/user.container";
import UserDetailsContainer from "../../users/containers/userDetails.container";
import UsersContainer from "../../users/containers/users.container";
import WarehouseContainer from '../../warehouse/containers/warehouse.container';
import WarehouseDetailsContainer from '../../warehouse/containers/warehouseDetails.container';
import WarehousePositionContainer from '../../warehouse/containers/warehousePosition.container';
import WarehousePositionEditContainer from "../../warehouse/containers/warehousePositionEdit.container";
import WarehousePositionDetailsContainer from '../../warehouse/containers/warehousePositionDetails.container';
import WarehouseListContainer from '../../warehouse/containers/warehouses.container';
import '../application.css';
import MainLoader from '../components/mainLoader';
import ErrorReportContainer from "./errorReport";
import NavbarContainer from './navbar.container';

class ContainerContainer extends React.Component {
    loader() {
        const { isLoading } = this.props.app;
        if (isLoading) {
            return <MainLoader />
        }
    }

    render() {
        return (
            <div>
                <Router>
                    <NavbarContainer {...this.props} />
                    <div style={{ position: 'relative', width: "100%", height: "100%" }}>
                        {this.loader()}
                        {/* routy */}
                        <Route exact path="/" component={DashboardContainer} />
                        <Route exact path="/report" component={ErrorReportContainer} />
                        <Route exact path="/profile/:id" component={ProfileContainer} />
                        {AclService.setIsAllowedState('document', this.props.auth.isAdmin) ? <Route exact path="/documents" component={DocumentsContainer} /> : null}
                        {AclService.setIsAllowedState('document', this.props.auth.isAdmin) ? <Route exact path="/documents/details/:id" component={DocumentDetailsContainer} /> : null}
                        {AclService.isAllowedAction('document', 'document', 'advancedEdit', this.props.auth.isAdmin) ? <Route exact path="/documents/edit/:id" component={DocumentEditContainer} /> : null}
                        {AclService.isAllowedAction('document', 'document', 'manualCompletion', this.props.auth.isAdmin) ? <Route exact path="/documents/completion/:id" component={DocumentCompletionContainer} /> : null}
                        {AclService.isAllowedAction('document', 'import', 'enovaImport', this.props.auth.isAdmin) ? <Route exact path="/documents/enovaImport" component={EnovaImportContainer} /> : null}
                        {AclService.setIsAllowedState('product', this.props.auth.isAdmin) ? <Route exact path="/products" component={ProductsContainer} /> : null}
                        {AclService.setIsAllowedState('product', this.props.auth.isAdmin) ? <Route exact path="/products/details/:id" component={ProductDetailsContainer} /> : null}
                        {AclService.setIsAllowedState('product', this.props.auth.isAdmin) ? <Route exact path="/products/product/:id?" component={ProductContainer} /> : null}
                        {AclService.setIsAllowedState('generator', this.props.auth.isAdmin) ? <Route exact path="/generator/list" component={GeneratorlistContainer} /> : null}
                        {AclService.setIsAllowedState('generator', this.props.auth.isAdmin) ? <Route exact path="/generator/code" component={GeneratorCodeContainer} /> : null}
                        {AclService.setIsAllowedState('warehouse', this.props.auth.isAdmin) ? <Route exact path="/warehouses" component={WarehouseListContainer} /> : null}
                        {AclService.setIsAllowedState('warehouse', this.props.auth.isAdmin) ? <Route exact path="/warehouses/details/:id" component={WarehouseDetailsContainer} /> : null}
                        {AclService.setIsAllowedState('warehouse', this.props.auth.isAdmin) ? <Route exact path="/warehouses/position" component={WarehousePositionContainer} /> : null}
                        {AclService.setIsAllowedState('warehouse', this.props.auth.isAdmin) ? <Route exact path="/warehouses/warehouse" component={WarehouseContainer} /> : null}
                        {AclService.setIsAllowedState('warehouse', this.props.auth.isAdmin) ? <Route exact path="/warehouses/position/:id" component={WarehousePositionDetailsContainer} /> : null}
                        {AclService.isAllowedAction('warehouse', 'position', 'update', this.props.auth.isAdmin)? <Route exact path="/position/edit/:id" component={WarehousePositionEditContainer} /> : null}
                        {AclService.isAllowedAction('application', 'user', 'get', this.props.auth.isAdmin) ? <Route exact path="/users" component={UsersContainer} /> : null}
                        {AclService.isAllowedAction('application', 'user', 'get', this.props.auth.isAdmin) ? <Route exact path="/users/details/:id" component={UserDetailsContainer} /> : null}
                        {AclService.isAllowedAction('application', 'user', 'update', this.props.auth.isAdmin) ? <Route exact path="/users/user/:id?" component={UserContainer} /> : null}
                        {AclService.isAllowedAction('application', 'role', 'get', this.props.auth.isAdmin) ? <Route exact path="/roles" component={RolesContainer} /> : null}
                        {AclService.isAllowedAction('application', 'role', 'get', this.props.auth.isAdmin) ? <Route exact path="/roles/details/:id" component={RoleDetailsContainer} /> : null}
                        {AclService.isAllowedAction('application', 'role', 'update', this.props.auth.isAdmin) ? <Route exact path="/roles/role/:id?" component={RoleContainer} /> : null}
                        {AclService.isAllowedAction('warehouse', 'inventory', 'get', this.props.auth.isAdmin) ? <Route exact path="/inventories" component={InventoriesContainer} /> : null}
                        {AclService.isAllowedAction('warehouse', 'inventory', 'create', this.props.auth.isAdmin) ? <Route exact path="/inventories/inventory/:id?" component={InventoryContainer} /> : null}
                        {AclService.isAllowedAction('warehouse', 'inventory', 'get', this.props.auth.isAdmin) ? <Route exact path="/inventories/details/:id" component={InventoryDetailsContainer} /> : null}
                        {AclService.isAllowedAction('statistic', 'report', 'create', this.props.auth.isAdmin) ? <Route exact path="/reports" component={ReportContainer} /> : null}
                        {this.props.auth.isAdmin ? <Route exact path="/settings" component={SettingsContainer} /> : null}
                        {this.props.auth.isAdmin ? <Route exact path="/administration" component={AdministrationContainer} /> : null}
                        {/* {this.props.auth.isAdmin ? <Route exact path="/administration/messages" component={MessagesContainer} /> : null} */}
                    </div>

                </Router>
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        app: store.app,
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerContainer);