import React from 'react';
import { connect } from 'react-redux';
import { getOptions, clearOptions } from '../actions/autocomplete.actions';
import Autocomplete from '../components/autocomplete.component';

class AutocompleteContainer extends React.Component {
    render() {
        return (
            <div>
                <Autocomplete {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        options: store.autocomplete.options,
        auth: store.auth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getOptions: (content, name, query, fields) => {
            dispatch(getOptions(content, name, query, fields));
        },
        clearOptions: (name) => {
            dispatch(clearOptions(name));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteContainer);