import React from 'react';

export default class DocumentSingleValue extends React.Component {
    render() {
        const { value, width } = this.props
        return (
            <div className="document-border" style={{ width: width }}>
                <p>{value}</p>
            </div>
        )
    }
} 