import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import ComponentNavbar from '../../application/components/componentNavbar';
import InputSelect from '../../application/components/inputSelect';
import Pagination from '../../application/components/pagination';
import Autocomplete from '../../application/containers/autocomplete.container';
import Acl from '../../services/acl.service';
import DictManager from '../../services/dict.service';
import DocumentListItem from './documents.listItem';

export default class Documents extends React.Component {
    state = {
        type: '',
        page: 1
    }

    componentDidMount = () => {
        const { getDocuments, documents: { offset, documentsPerPage } } = this.props;
        getDocuments();
        const page = Math.floor(offset / documentsPerPage) + 1;
        this.setState({
            page: page
        })
        this.setState({
            type: this.props.documents.filters.type
        })
    }

    navbarButton = () => {
        const { isAdmin } = this.props.auth;
        let hasSymfoniaPermission = Acl.isAllowedAction('document', 'import', 'symfoniaImport', isAdmin);
        let hasEnovaPermission = Acl.isAllowedAction('document', 'import', 'enovaImport', isAdmin);

        if (!hasEnovaPermission && !hasSymfoniaPermission) {
            return null;
        } else {
            return (
                <div className="btn-group dropright">
                    <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown">
                        Importuj dokumenty&nbsp;&nbsp;
                    </button>
                    <div className="dropdown-menu">
                        {/* {hasSymfoniaPermission && <Link to={`documents/symfoniaImport`} className='dropdown-item'>
                            Symfonia
                        </Link>} */}
                        {hasEnovaPermission && <Link to={`documents/enovaImport`} className='dropdown-item'>
                            Enova
                        </Link>}
                    </div>
                </div>);
        }

    }

    documentsNavbar = () => {
        return (
            <div className="row" style={{ marginTop: 16 }}>
                <div className='col-md-12'>
                    <div className='d-flex align-items-center row'>
                        <div className='col-md-2'></div>
                        <div className='col-md-8'>
                            <h3 className='text-center'>Dokumenty</h3>
                        </div>
                        <div className='col-md-2 d-flex justify-content-end'>
                            <Link to={`documents/import`}>
                                <button
                                    type='button'
                                    className='btn btn-outline-primary'> Importuj dokumenty </button>
                            </Link>
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
        )
    }

    onFormSubmit = (e) => {
        e.preventDefault();
    }

    changeFilter = (e, name) => {
        const { setFilters, getDocuments } = this.props;
        let value = e.target.value;

        if (name === "dateFrom") {
            if (value === "") {
                value = moment().subtract(2, 'd').startOf('day').toISOString();
            } else {
                value = moment(value).startOf('day').toISOString();
            }
        }
        if ("name" === "type" && value === "Wszystkie") {
            value = "";
        }
        if (name === "dateTo") {
            if (value === "") {
                value = moment().endOf('day').toISOString();
            } else {
                value = moment(value).endOf('day').toISOString();
            }
        }
        if (["status", "transportType", "inspectionStatus"].indexOf(name) > -1 && value == "0") {
            value = undefined;
        }
        this.setState({ page: 1 });
        setFilters(name, value);
        getDocuments();
    }

    changeAutocompleteFilter = (name, value) => {
        const { setFilters, getDocuments } = this.props;
        this.setState({ page: 1 });
        setFilters(name, value);
        getDocuments();
    }

    resetFilters = () => {
        const { resetFilters, getDocuments } = this.props;
        resetFilters();
        this.setState({ type: "", page: 1 });
        getDocuments();
    }

    productSearch = () => {
        const { documents: { filters } } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-md-3">
                        {filters.warehouseFrom && (
                            <h6>
                                <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.changeAutocompleteFilter("warehouseFrom", null)}>
                                    {filters.warehouseFrom.code}
                                    {"  "}
                                    <button className="btn btn-sm btn-secondary">X</button>
                                </span>
                            </h6>
                        )}
                    </div>
                    <div className="col-md-3">
                        {filters.warehouseTo && (
                            <h6>
                                <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.changeAutocompleteFilter("warehouseTo", null)}>
                                    {filters.warehouseTo.code}
                                    {"  "}
                                    <button className="btn btn-sm btn-secondary">X</button>
                                </span>
                            </h6>
                        )}
                    </div>
                    <div className="col-md-3 offset-3">
                        {filters.product && (
                            <h6>
                                <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.changeAutocompleteFilter("product", null)}>
                                    {filters.product.name}
                                    {"  "}
                                    <button className="btn btn-sm btn-secondary">X</button>
                                </span>
                            </h6>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label>Magazyn</label>
                        <Autocomplete onSelect={(value) => this.changeAutocompleteFilter("warehouseFrom", value)} content="warehouse" name="warehouseFromSearch" />
                    </div>
                    <div className="col-md-3">
                        <label>Magazyn powiązany</label>
                        <Autocomplete onSelect={(value) => this.changeAutocompleteFilter("warehouseTo", value)} content="warehouse" name="warehouseToSearch" />
                    </div>
                    <div className="col-md-3">
                        <label>Klient</label>
                        <input type="text" className="form-control" placeholder="Wpisz nazwę klienta" value={filters.customer} onChange={(e) => this.changeFilter(e, 'customer')} />
                    </div>
                    <div className="col-md-3">
                        <label>Produkt</label>
                        <Autocomplete onSelect={(value) => this.changeAutocompleteFilter("product", value)} content="product" name="productSearch" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <label>Status</label>
                        <select className="form-control" value={filters.status || "0"} onChange={(e) => this.changeFilter(e, 'status')}>
                            <option value="0">Wybierz</option>
                            <option value="1">Do realizacji</option>
                            <option value="2">W realizacji</option>
                            <option value="3">Zrealizowane</option>
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label>Status sprawdzania</label>
                        <select className="form-control" value={filters.inspectionStatus || "0"} onChange={(e) => this.changeFilter(e, 'inspectionStatus')}>
                            <option value="0">Wybierz</option>
                            <option value="1">Do sprawdzenia</option>
                            <option value="2">W trakcie sprawdzania</option>
                            <option value="3">Sprawdzony</option>
                        </select>
                    </div>
                    <form
                        className="col-md-2"
                        onSubmit={this.onFormSubmit}
                    >
                        <label>Typ dokumentu</label>
                        <select className="form-control" value={filters.type || "0"} onChange={(e) => this.changeFilter(e, 'type')}>
                            {DictManager.getOptionsByType(4, "importType", "importType")}
                        </select>
                    </form>
                    <div className="col-md-2">
                        <label>Typ transportu</label>
                        <select className="form-control" value={filters.transportType || "0"} onChange={(e) => this.changeFilter(e, 'transportType')}>
                            {DictManager.getOptionsByType(2)}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label>Data dodania (od)</label>
                        <input type="date" className="form-control" value={moment(filters.dateFrom).format("YYYY-MM-DD")} onChange={(e) => this.changeFilter(e, 'dateFrom')} />
                    </div>
                    <div className="col-md-2">
                        <label>Data dodania (do)</label>
                        <input type="date" className="form-control" value={moment(filters.dateTo).format("YYYY-MM-DD")} onChange={(e) => this.changeFilter(e, 'dateTo')} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 offset-4">
                        {filters.completionUser && (
                            <h6>
                                <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.changeAutocompleteFilter("completionUser", null)}>
                                    {filters.completionUser.name}
                                    {"  "}
                                    <button className="btn btn-sm btn-secondary">X</button>
                                </span>
                            </h6>
                        )}
                    </div>
                    <div className="col-md-3">
                        {filters.inspectionUser && (
                            <h6>
                                <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.changeAutocompleteFilter("inspectionUser", null)}>
                                    {filters.inspectionUser.name}
                                    {"  "}
                                    <button className="btn btn-sm btn-secondary">X</button>
                                </span>
                            </h6>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <label>Wyszukaj dokument</label>
                        <input type="text" className="form-control" placeholder="Wpisz numer dokumentu" value={filters.query} onChange={(e) => this.changeFilter(e, 'query')} />
                    </div>
                    <div className="col-md-3">
                        <label>Realizujący</label>
                        <Autocomplete onSelect={(value) => this.changeAutocompleteFilter("completionUser", value)} content="user" name="completionUserSearch" />
                    </div>
                    <div className="col-md-3">
                        <label>Sprawdzający</label>
                        <Autocomplete onSelect={(value) => this.changeAutocompleteFilter("inspectionUser", value)} content="user" name="inspectionUser" />
                    </div>
                    <div className="col-md-2 pt-1 text-right">
                        <button className="btn btn-secondary mt-4" onClick={() => this.resetFilters()}>Usuń filtry</button>
                    </div>
                </div>
            </div>);
    }

    statusKey = () => {
        return (
            <div className="d-flex flex-row justify-content-between mt-3">
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <div className="document-list-status inspection-status-1" />
                    <div style={{ marginLeft: 8 }}>
                        Do sprawdzenia
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <div className="document-list-status inspection-status-2" />
                    <div style={{ marginLeft: 8 }}>
                        W trakcie sprawdzania
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <div className="document-list-status inspection-status-3" />
                    <div style={{ marginLeft: 8 }}>
                        Sprawdzony
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <div className="document-list-status document-status-1" />
                    <div style={{ marginLeft: 8 }}>
                        Do realizacji
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <div className="document-list-status document-status-2" />
                    <div style={{ marginLeft: 8 }}>
                        W realizacji
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <div className="document-list-status document-status-3" />
                    <div style={{ marginLeft: 8 }}>
                        Zrealizowany
                    </div>
                </div>
            </div>
        )
    }

    setPage = (page) => {
        this.setState({ page: page });
    }

    navigationButtons = () => {
        const { documents: { offset, documentsPerPage, total }, changeOffset, getDocuments } = this.props;
        const { page } = this.state;

        if (total !== 0) {
            return (
                <Pagination
                    getDocuments={getDocuments}
                    changeOffset={changeOffset}
                    offset={offset}
                    total={total}
                    perPage={documentsPerPage}
                    setPage={this.setPage}
                    page={page}
                />
            )
        }

    }

    singleDocument = (documents) => {
        const { documents: { activeDocument } } = this.props;
        return documents.map((document, i) => {

            return (
                <DocumentListItem
                    key={document._id + i}
                    document={document}
                    isActive={document._id == activeDocument}
                />
            )
        })
    }

    documentList = () => {
        const { documentList } = this.props.documents;

        if (documentList && documentList.length > 0) {
            return (
                <ul className="list-group document-list">
                    {this.singleDocument(documentList)}
                </ul>
            )
        } else {
            return (
                <ul className="d-flex justify-content-center mt-5">
                    <div>Brak dokumentów</div>
                </ul>
            )
        }
    }

    render() {
        return (
            <div className="d-flex flex-column container documents-container">
                <ComponentNavbar
                    title="Dokumenty"
                    button={this.navbarButton()}
                />
                {this.productSearch()}

                {this.statusKey()}

                {/* Document list */}
                {this.navigationButtons()}
                {this.documentList()}
                {this.navigationButtons()}

            </div>
        )
    }

    componentWillUnmount() {
        const { setActiveDocument } = this.props;
        setActiveDocument(null);
    }
}