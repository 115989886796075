import React from 'react';
import _ from 'lodash';
import Acl from "../../services/acl.service";

export default class Autocomplete extends React.Component {

    constructor() {
        super();
        this.state = {
            query: ""
        }
    }

    onOptionSelect = (option) => {
        const { onSelect } = this.props;
        onSelect(option);
        this.hideOptions();
    }

    getOptionLabel = (option) => {
        const { content } = this.props;
        switch (content) {
            case "position": {
                return option.code;
            }
            default: {
                return `${option.name}${option.code ? ` - kod: ${option.code}` : ''}`;
            }
        }
    }

    renderOptions = () => {
        const { options, name } = this.props;
        if (_.isArray(options[name])) {
            return options[name].map((option, index) => {
                let label = this.getOptionLabel(option);
                return <li className="list-group-item user-select-none list-group-item-action" onClick={() => this.onOptionSelect(option)} key={`option-${name}-${index}`}>{label}</li>
            });
        }
        return null;
    }

    onSearch = (e) => {
        const { query } = this.state;
        const { clearOptions, getOptions, content, name, fields } = this.props;
        const newQuery = e.target.value;
        this.setState({ query: newQuery });
        if (query.length >= 3 && newQuery.length < 3) {
            clearOptions(name);
        } else if (newQuery.length >= 3) {
            _.defer(() => {
                getOptions(content, name, newQuery, fields);
            });
        }
    }

    showOptions = () => {
        const { name } = this.props;
        let el = document.getElementById(name);
        el.classList.remove("d-none");
    }

    hideOptions = () => {
        const { name } = this.props;
        let el = document.getElementById(name);
        el.classList.add("d-none");
    }

    checkPermission = () => {
        const { auth, content } = this.props;

        if (_.get(auth, 'isAdmin', false)) {
            return true;
        } else {
            switch (content) {
                case 'warehouse': {
                    return Acl.isAllowedAction("warehouse", "warehouse", "get", _.get(auth, 'isAdmin', false));
                }
                case 'position': {
                    return Acl.isAllowedAction("warehouse", "position", "get", _.get(auth, 'isAdmin', false));
                }
                case 'role': {
                    return Acl.isAllowedAction("application", "role", "get", _.get(auth, 'isAdmin', false));
                }
                case 'product': {
                    return Acl.isAllowedAction("product", "product", "get", _.get(auth, 'isAdmin', false));
                }
                case 'user': {
                    return Acl.isAllowedAction("application", "user", "get", _.get(auth, 'isAdmin', false));
                }
                default: {
                    return false;
                }
            }

        }
    }

    render() {
        const { name, disabled } = this.props;
        const options = this.renderOptions();
        const hasPermission = this.checkPermission();

        return (
            <div className="form-group flex-row autocomplete-container">
                <input
                    className="form-control"
                    placeholder="Wyszukaj"
                    disabled={!hasPermission || disabled}
                    onChange={(e) => { this.onSearch(e) }}
                    onFocus={() => this.showOptions()}
                />
                <div className="autocomplete-button">
                    <button className="btn btn-default" onClick={() => this.hideOptions()}><i className="fa fa-times"></i></button>
                </div>
                <ul className="list-group autocomplete-options d-none" id={name}>
                    {options}
                </ul>
            </div>
        );
    }

    componentWillUnmount() {
        const { clearOptions, name } = this.props;
        clearOptions(name);
    }
}