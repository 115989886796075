import { toast } from 'react-toastify';
import { isLoading } from '../../application/actions/application.actions';
import Api from '../../services/api';
import _ from 'lodash';

const namespace = 'ENOVA_IMPORT';
export const SET_ERRORS = `SET_ERRORS_${namespace}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS_${namespace}`;

const clearErrors = () => dispatch => {
    dispatch({
        type: CLEAR_ERRORS
    });
}

const setErrors = (errors, info) => dispatch => {
    dispatch({
        type: SET_ERRORS,
        errors,
        info
    });
}

export const uploadDocument = (file) => async (dispatch) => {
    try {
        dispatch(isLoading(true));
        dispatch(clearErrors());

        let formData = new FormData();
        formData.append('file', file);

        let res = await Api.post('/document/import/enovaImport', formData, true, true);

        dispatch(isLoading(false));

        if (res.success) {
            const documents = res.documents;
            const success = dispatch(parseImportResult(documents, res.data, res.info));
            if (success) {
                toast('Dokument zaimportowany');
            } else {
                toast('Dokument nie został zaimportowany');
            }
        } else {
            toast("Dokument nie został zaimportowany");
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast("Dokument nie został zaimportowany");
    }
}

const parseImportResult = (documents, data, info) => dispatch => {
    let errors = [];
    let success = true;

    _.each(documents, (doc, i) => {
        let document = {
            document: doc.documentNumber,
            correct: true,
            products: [],
            warehouseFrom: null,
            documentNumber: null,
        };

        let dataProducts = _.castArray(_.get(data, `[${i}].Pozycje.PozycjaDokHandlowego`, []));

        if (doc.imported === false) {

            if (doc.err) {
                document.correct = false;
                success = false;

                document.documentNumber = _.get(doc.err, "documentNumber", null);
                let warehouseError = _.get(doc.err, "warehouseFrom.warehouse");
                if (warehouseError) {
                    document.warehouseFrom = warehouseError;
                }

                if (doc.err.products) {
                    _.each(doc.err.products, (prod, j) => {
                        let product = {
                            displayName: _.get(dataProducts, `[${j}].Nazwa`)
                        };
                        product = _.merge(product, prod);
                        document.products.push(product);
                    });
                }

            }
        }
        if (doc.hasOwnProperty("autoCompleted")) {
            document.autoCompleted = doc.autoCompleted;
            if (doc.completionErr) {
                document.completionErrors = doc.completionErr;
            }
        }
        errors.push(document);
    });

    dispatch(setErrors(errors, info));
    return success;
}