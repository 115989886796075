import React from 'react';
import { connect } from 'react-redux';
import { setActiveDocument } from "../actions/documents.actions";
import {
    clearDocument,
    deleteDocument,
    getDocument,
    getReservations,
    clearReservations,
    saveQuickEdit,
    handleGenerateDocumentCodes,
    setPrintOptions,
    resetPrintOptions,
    undoDocument
} from '../actions/document.actions';
import DocumentDetails from '../components/documentDetails.component';
import '../documents.css';

class DocumentDetailsContainer extends React.Component {

    render() {
        return (
            <div>
                <DocumentDetails
                    {...this.props}
                />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        isAdmin: store.auth.isAdmin,
        document: store.document,
        printOptions: store.document.printOptions
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDocument: (id) => {
            dispatch(getDocument(id))
        },
        clearDocument: () => {
            dispatch(clearDocument())
        },
        deleteDocument: (id, history) => {
            dispatch(deleteDocument(id, history));
        },
        saveQuickEdit: (data) => {
            dispatch(saveQuickEdit(data));
        },
        getReservations: () => {
            dispatch(getReservations())
        },
        clearReservations: () => {
            dispatch(clearReservations())
        },
        handleGenerateDocumentCodes: () => {
            dispatch(handleGenerateDocumentCodes());
        },
        setActiveDocument: (activeDocument) => {
            dispatch(setActiveDocument(activeDocument));
        },
        setPrintOptions: (value, name) => {
            dispatch(setPrintOptions(value, name));
        },
        resetPrintOptions: () => {
            dispatch(resetPrintOptions());
        },
        undoDocument: (history) => {
            dispatch(undoDocument(history));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetailsContainer);