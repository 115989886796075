import React from 'react';

export default class SingleDetail extends React.Component {
    render() {
        const { label, value, smallFont } = this.props;
        return (
            <div>
                <p className="single-detail-label">
                    {label}
                </p>

                <p className={(smallFont) ? "single-detail-value-small" : "single-detail-value"}>
                    {value
                        ? (typeof value === 'boolean') ? 'Tak' : value
                        : '-'}
                </p>
            </div>
        )

    }
}