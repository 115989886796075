import React from 'react';
import Loader from 'react-loader-spinner'


export default class ComponentLoader extends React.Component {
    render() {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height="100"
                    width="100"
                />
            </div>

        )
    }
}