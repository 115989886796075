import _ from 'lodash';
import { toast } from 'react-toastify';
import Api from "../../services/api";

const namespace = "APP";

export const IS_LOADING = `IS_LOADING_${namespace}`;

export const isLoading = (isLoading) => dispatch => {
    dispatch({
        type: IS_LOADING,
        isLoading: isLoading
    });
}

export const sendErrorReport = (data, history) => async (dispatch) => {

    dispatch(isLoading(true));

    let formData = new FormData();

    _.each(data, (value, key) => {
        if (_.isArray(value)) {
            _.each(value, (val, i) => {
                formData.append(`${key}[]`, val);
            });
        } else {
            formData.append(key, value);
        }
    });

    let res = await Api.post('/helpdesk/ticket', formData, false, true, 'https://helpdesk.overcloud.usermd.net');

    dispatch(isLoading(false));

    if (res && res.success) {
        toast("Wysłano zgłoszenie");
        history.push("/");
    } else {
        toast("Wystąpił błąd");
    }
}