import _ from "lodash";
import moment from "moment";
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import ComponentNavbar from '../../application/components/componentNavbar';
import Autocomplete from "../../application/containers/autocomplete.container";

export default class GeneratorCodeComponent extends Component {

    state = {
        noSeries: true,
        selectedProduct: null
    }

    handleSaveCodeButton = () => {
        const { saveCode } = this.props;
        saveCode();
    }

    changeField = (name, value) => {
        const { changeField } = this.props;
        changeField(name, value);
    }

    setAutocompleteProduct = (product) => {
        const { changeField } = this.props;
        changeField("eanNr", product.ean);
        changeField("expirationDate", moment().add(product.expiresIn, "months").toISOString());
        if(_.isNumber(product.nettoWeight)) {
            changeField("nettoWeight", product.nettoWeight);
        }
        if(_.isNumber(product.tareWeight)) {
            changeField("tareWeight", product.tareWeight);
        }
        this.setState({ noSeries: product.noSeries, selectedProduct: product });
    }

    render() {
        const { code: { seriesNr, eanNr, expirationDate, nettoWeight, tareWeight }, errors, saved } = this.props;
        const { noSeries, selectedProduct } = this.state;

        if (saved) {
            return <Redirect to="/generator/list" />
        }

        return (
            <div className="d-flex flex-column container product-container">
                <ComponentNavbar
                    title="Dodawanie kodu"
                />
                <div className="row mt-5">
                    <div className="col-12">
                        <label>Wyszukaj produkt</label>
                        <Autocomplete onSelect={(value) => this.setAutocompleteProduct(value)} content="product" name="productSearch" fields="name,ean,expiresIn,noSeries,code,nettoWeight,tareWeight" />
                        {selectedProduct && <h6>Wybrano produkt:&nbsp;{selectedProduct.name} - kod: {selectedProduct.code}</h6>}
                    </div>
                    <div className="col-6">
                        <label>Numer EAN</label>
                        <input className="form-control" name="eanNr" placeholder="Wpisz numer EAN" value={eanNr} onChange={(e) => this.changeField("eanNr", e.target.value)} disabled={true} />
                        {errors.eanNr && <div><span className="text-danger">{errors.eanNr}</span><br /></div>}
                    </div>
                    <div className="col-6">
                        <label>Numer serii</label>
                        <input className="form-control" name="seriesNr" placeholder="Wpisz numer serii" value={seriesNr} onChange={(e) => this.changeField("seriesNr", e.target.value)} disabled={noSeries} title={noSeries ? "Produkt nie posiada numeru serii" : "Wpisz numer serii"} />
                        {errors.seriesNr && <div><span className="text-danger">{errors.seriesNr}</span><br /></div>}
                    </div>
                    <div className="col-6 mt-3">
                        <label>Data ważności</label>
                        <input type="date" className="form-control" value={moment(expirationDate).format("YYYY-MM-DD")} onChange={(e) => this.changeField("expirationDate", e.target.value)} />
                        {errors.expirationDate && <div><span className="text-danger">{errors.expirationDate}</span><br /></div>}
                    </div>
                    <div className="col-6 mt-3">
                        <label>Waga netto</label>
                        <input type="number" className="form-control" defaultValue={nettoWeight} onChange={(e) => this.changeField("nettoWeight", e.target.value)} />
                        {errors.nettoWeight && <div><span className="text-danger">{errors.nettoWeight}</span><br /></div>}
                    </div>
                    <div className="col-6 mt-3">
                        <label>Tara</label>
                        <input type="number" className="form-control" defaultValue={tareWeight} onChange={(e) => this.changeField("tareWeight", e.target.value)} />
                        {errors.tareWeight && <div><span className="text-danger">{errors.tareWeight}</span><br /></div>}
                    </div>
                </div>
                <div className="justify-content-center mb-5 mt-5 text-center">
                    <button onClick={this.handleSaveCodeButton} type="button" className="btn btn-primary">Zapisz</button>
                </div>
            </div>
        )

    }

    componentWillUnmount() {
        const { clearCode, clearErrors } = this.props;
        clearCode();
        clearErrors();
    }

}