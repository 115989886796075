import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ComponentNavbar from '../../application/components/componentNavbar';
import Pagination from '../../application/components/pagination';
import Acl from '../../services/acl.service';
import GeneratorListRecordComponent from './generator.list.record/generator.list.record.component';
import GeneratorNewRecordComponent from './generator.list.record/generator.newRecord.component';
import './generator.list.record/generator.list.record.css';

export default class GeneratorListComponent extends Component {

    state = {
        page: 1
    }

    componentDidMount() {
        const { getCodes } = this.props;
        getCodes();
    }

    setPage = (page) => {
        this.setState({ page: page });
    }

    navigationButtons = () => {
        const { total, offset, limit, codes, getCodes, setOffset } = this.props;
        const { page } = this.state;

        if (total && total > 0) {
            if (codes) {
                return (
                    <Pagination
                        getDocuments={getCodes}
                        changeOffset={setOffset}
                        offset={offset}
                        total={total}
                        perPage={limit}
                        setPage={this.setPage}
                        page={page}
                    />
                )
            }
        }
        return null;
    }

    changeFilter = (e, name) => {
        const { setFilters } = this.props;
        let value = e.target.value;
        setFilters(name, value);
        this.setState({ page: 1 });
    }

    resetFilters = () => {
        const { resetFilters, getCodes } = this.props;
        resetFilters();
        this.setState({ page: 1 });
        getCodes();
    }

    renderSearchBar = () => {
        const { filters, getCodes } = this.props;

        return (
            <div>
                <div className="row mb-5">
                    <div className="col-md-3">
                        <label>Nazwa</label>
                        <input type="text" className="form-control" placeholder="Wpisz nazwę produktu" value={filters.name} onChange={(e) => this.changeFilter(e, 'name')} />
                    </div>
                    <div className="col-md-3">
                        <label>EAN</label>
                        <input type="text" className="form-control" placeholder="Wpisz numer ean" value={filters.eanNr} onChange={(e) => this.changeFilter(e, 'eanNr')} />
                    </div>
                    <div className="col-md-3">
                        <label>Numer serii</label>
                        <input type="text" className="form-control" placeholder="Wpisz numer serii" value={filters.seriesNr} onChange={(e) => this.changeFilter(e, 'seriesNr')} />
                    </div>
                    <div className="col-md-1 pt-1 text-right">
                        <button className="btn btn-primary mt-4" onClick={() => getCodes()}>Wyszukaj</button>
                    </div>
                    <div className="col-md-2 pt-1 text-right">
                        <button className="btn btn-secondary mt-4" onClick={() => this.resetFilters()}>Usuń filtry</button>
                    </div>
                </div>

            </div>

        )
    }

    renderList = () => {
        let codes = this.props.codes;

        if (codes && codes.length > 0) {
            return (
                <ul className="list-group document-list mb-5">
                    {codes.map((code, index) => {
                        if (code.newFormat) {
                            return (
                                <li key={index} className="flex-fill list-group-item list-group-item-action">
                                    <GeneratorNewRecordComponent code={code} />
                                </li>
                            );
                        } else {
                            return (
                                <li key={index} className="flex-fill list-group-item list-group-item-action">
                                    <GeneratorListRecordComponent
                                        series={code.seriesNr}
                                        ean={code.eanNr}
                                        img={code.image}
                                        name={code.name}
                                        productionDate={code.productionDate}
                                        unitsInPackage={code.unitsInPackage}
                                        expirationDate={code.expirationDate}
                                        PAO={code.PAO}
                                        expiresIn={code.expiresIn}
                                    />
                                </li>
                            );
                        }
                    })}
                </ul>
            )
        } else {
            return (
                <ul className="d-flex justify-content-center">
                    <div>Brak kodów</div>
                </ul>
            )
        }
    }

    onInputChange = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    navbarButton = () => {
        const { auth } = this.props;
        const permission = Acl.isAllowedAction('generator', 'generator', 'create', auth.isAdmin);

        if (permission) return (
            <Link to="/generator/code">
                <button
                    type="button"
                    className="btn btn-outline-primary">Dodaj</button>
            </Link>
        )
    }

    render() {
        const searchBar = this.renderSearchBar();
        return (
            <div className="d-flex flex-column container">
                <ComponentNavbar
                    title="Lista kodów"
                    button={this.navbarButton()}
                />
                {searchBar}
                {this.navigationButtons()}
                {this.renderList()}
                {this.navigationButtons()}
            </div>

        )
    }

    componentWillUnmount() {
        const { clearCodes, resetFilters } = this.props;
        resetFilters();
        clearCodes();
    }
}