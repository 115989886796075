import _ from 'lodash';
import React from 'react';

class DictManager {

    constructor() {
        this.dicts = [];
    }

    //Funkcja ustawia pobrane słowniki
    setDicts(dicts) {
        this.dicts = dicts;
    }

    //Funkcja zwraca wszystie słowniki
    getDicts() {
        return this.dicts;
    }

    //Funkcja zwraca słownik o danym typie jeśli istnieje
    getDictByType = (type) => {
        return _.find(this.dicts, (dict) => dict.type == type);
    }

    //Funkcja zwraca wartości słownika danego typu jako obiekt z parami nazwa wartości: wartość
    getNameValueObject = (type) => {
        let obj = {};
        let dictionary = _.find(this.dicts, (dict) => dict.type == type);
        if (dictionary) {
            _.each(dictionary.values, (val) => {
                obj[val.name] = val.value;
            });
        }
        return obj;
    }

    //Funkcja zwraca nazwę danej wartości ze słownika o danym typie
    getNameByValue = (type, value) => {
        let dictionary = _.find(this.dicts, (dict) => dict.type == type);
        if (dictionary) {
            let obj = _.find(dictionary.values, (val) => val.value == value);
            if (obj) {
                return obj.name;
            }
        }
        return null;
    }


    //Funkcja zwraca tagi <options> z wartościami słownika do selecta na podstawie typu słownika
    getOptionsByType = (type, labelField = "name", valueField = "value") => {
        let dictionary = _.find(this.dicts, (dict) => dict.type == type);
        if (dictionary) {
            let types = dictionary.values.map((value, index) => {
                return <option key={`options-${type}-${index}`} value={value[valueField]}>{value[labelField]}</option>
            });
            return _.concat([<option key={`options-${type}`} value="0">Wybierz</option>], types);
        }
        return [];
    }
}


export default new DictManager();