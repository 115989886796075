import { toast } from 'react-toastify';
import moment from 'moment';
import Api from '../../services/api';

import { isLoading } from '../../application/actions/application.actions';
moment.locale('pl');

const namespace = 'DOCUMENTS';
export const GET_DOCUMENTS = `GET_DOCUMENTS_${namespace}`;
export const CHANGE_OFFSET = `CHANGE_OFFSET_${namespace}`;
export const RESET_PARAMS = `RESET_PARAMS_${namespace}`;
export const SET_FILTERS = `SET_FILTERS_${namespace}`;
export const SET_ACTIVE_DOCUMENT = `SET_ACTIVE_DOCUMENT_${namespace}`;

export const getDocuments = () => (dispatch, getState) => {
    dispatch(isLoading(true));

    const documentsPerPage = getState().documents.documentsPerPage
    const offset = getState().documents.offset;
    const type = getState().documents.filters.type;
    const status = getState().documents.filters.status;
    const query = getState().documents.filters.query;
    const dateFrom = getState().documents.filters.dateFrom;
    const dateTo = getState().documents.filters.dateTo;
    const warehouseFrom = getState().documents.filters.warehouseFrom;
    const warehouseTo = getState().documents.filters.warehouseTo;
    const customer = getState().documents.filters.customer;
    const product = getState().documents.filters.product;
    const inspectionUser = getState().documents.filters.inspectionUser;
    const completionUser = getState().documents.filters.completionUser;
    const transportType = getState().documents.filters.transportType;
    const inspectionStatus = getState().documents.filters.inspectionStatus;

    const params = {
        limit: documentsPerPage,
        offset: offset,
        "sort[status]": 1,
        "sort[transportType]": -1,
        "createdAt[gte]": dateFrom,
        "createdAt[lte]": dateTo,
        "documentNumber": query,
        "checkAmount": true,
        "select": "documentNumber,status,inspection,products,warehouseFrom,transportType,transportDescription,customer,action"
    }
    if (type) {
        if(type==="MM+") {
            params['type[in]'] = "MM%2B"; 
        } else {
            params['type[in]'] = type;
        }
    }
    if (status !== undefined) {
        params["status"] = status;
    }
    if (warehouseFrom) {
        params["warehouseFrom.code"] = warehouseFrom.code;
    }
    if (warehouseTo) {
        params["warehouseTo.code"] = warehouseTo.code;
    }
    if (customer) {
        params["customer.name"] = customer;
    }
    if (product) {
        params["products.ean"] = product.ean;
    }
    if (inspectionUser) {
        params["inspection.inspectionUser"] = inspectionUser._id;
    }
    if (completionUser) {
        params["completionUser"] = completionUser._id;
    }
    if (transportType) {
        params["transportType"] = transportType;
    }
    if (inspectionStatus) {
        params["inspection.status"] = inspectionStatus;
    }

    Api.get('/document/document', params).then(res => {

        if (res.success) {
            const documents = res.documents;
            dispatch({
                type: GET_DOCUMENTS,
                documents,
                total: res.total
            })
        } else {
            toast("Błąd pobierania dokumentów")
        }

        dispatch(isLoading(false));

    }).catch(err => {
        dispatch(isLoading(false));
        console.error(err);
    })
}

export const changeOffset = (offset) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_OFFSET,
        offset
    })
}


export const setFilters = (filter, value) => dispatch => {
    dispatch({
        type: SET_FILTERS,
        filter,
        value
    })
}

export const resetParams = () => dispatch => {
    dispatch({
        type: RESET_PARAMS
    })
}

export const setActiveDocument = (activeDocument) => dispatch => {
    dispatch({
        type: SET_ACTIVE_DOCUMENT,
        activeDocument: activeDocument
    });
}