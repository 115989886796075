import React from 'react';
import { connect } from 'react-redux';
import { changeOffset, getDocuments, resetParams, setFilters, setActiveDocument } from '../actions/documents.actions';
import Documents from '../components/documents.component';
import '../documents.css';

class DocumentsContainer extends React.Component {
    render() {
        return (
            <div>
                <Documents {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        documents: store.documents
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDocuments: () => {
            dispatch(getDocuments())
        },
        setFilters: (filter, value) => {
            dispatch(setFilters(filter, value))
        },
        changeOffset: (value) => {
            dispatch(changeOffset(value))
        },
        resetFilters: () => {
            dispatch(resetParams());
        },
        setActiveDocument: (activeDocument) => {
            dispatch(setActiveDocument(activeDocument));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsContainer);